<template>
  <v-container fluid class="pa-0 ma-0">
    <produtos></produtos>
  </v-container>
</template>

<script>
import Produtos from '@/components/home/Produtos.vue'

export default {
  components: {
    Produtos,
  },
}
</script>
